import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'index',
        path: `${APP_PREFIX_PATH}/index`,
        component: React.lazy(() => import('views/app-views/pages/home')),
    },
    {
        key: 'request',
        path: `${APP_PREFIX_PATH}/request/:prodId`,
        component: React.lazy(() => import('views/app-views/pages/request')),
    },
]

export const protectedRoutes = [
    // {
    //     key: 'dashboard.default',
    //     path: `${APP_PREFIX_PATH}/dashboards/default`,
    //     component: React.lazy(() => import('views/app-views/dashboards/default')),
    // }
]